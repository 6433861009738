import { sanitizeUrl } from '@braintree/sanitize-url';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import moment from 'moment';

import {
  AstroGranite,
  RegentGray,
  small,
  SunsetOrange,
  White,
} from 'js/styled/config';
import { internalHeaders, resolveRequest } from 'js/utils/fetch';
import {
  CONVERSION_EVENT_CATEGORY_MAP,
  GIFT_OPTIONS,
  LOGIN,
  LOGOUT_PATH,
  MONTHLY,
  POSTAL_CODE_COUNTRIES,
  PROMO_ELIGIBLE_OPTIONS_TVOD,
  TVOD_OPTIONS,
  YEARLY,
} from '~/apps/Customer/pages/checkout/constants';

export const discountPrice = (priceInCents = 0, discountPercentage = 0) => {
  const discountInCents =
    (parseFloat(100 - discountPercentage) / 100.0) * priceInCents;
  return (discountInCents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const escapeHtml = (unsafe) => {
  if (!unsafe) return unsafe;

  return decodeURIComponent(unsafe)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

export const getParam = (arg) => {
  const params = window.location.search.replace('?', '');
  return escapeHtml(
    params
      .substr(params.indexOf(`${arg}=`))
      .split('&')[0]
      .split('=')[1],
  );
};

export const returnToUrl = () => {
  if (!window.URLSearchParams) {
    return import('url-search-params-polyfill').then((URLSearchParams) => {
      const searchParams = new URLSearchParams(window.location.search);
      const returnUrl = searchParams.get('return_to');

      return returnUrl ? sanitizeUrl(returnUrl) : null;
    });
  }

  const urlParams = new URLSearchParams(window.location.search);
  const returnUrl = urlParams.get('return_to');

  return returnUrl ? sanitizeUrl(returnUrl) : null;
};

const hasDiscount = (price) => price && price.discount_percent > 0;

// Determines which price to default to on the checkout page
// When the product is a SVOD, default to using the monthly or yearly
// if its a TVOD use the rental, PWYW, or purchase price
export const determineBreakdown = (product, price) => {
  if (product.is_subscription) {
    if (
      getParam('plan') !== YEARLY &&
      (getParam('code') ||
        getParam('promocode') ||
        getParam('plan') === MONTHLY ||
        getParam('monthly') === '1')
    ) {
      return price.monthly || price.yearly;
    }

    return price.yearly || price.monthly;
  }

  if (getParam('code')) {
    if (hasDiscount(price.purchase)) {
      return price.purchase;
    }

    if (hasDiscount(price.rent)) {
      return price.rent;
    }
  }

  if (getParam('rent') === '1') {
    return price.rent || price.pwyw || price.purchase;
  }

  return price.pwyw || price.purchase || price.rent;
};

export const isCustomerAccessingFVOD = (customer, product) => {
  return customer.id && isFvod(product);
};

export const isFvod = (product) => product.is_subscription && product.is_free;

export const getCheckoutPath = (product, type) => {
  return `/checkout/${product.sku}/${type}${location.search}`;
};
export const getReceiptPath = (product) => {
  return getCheckoutPath(product, 'receipt');
};

export const getProcessingTransactionPath = (product) => {
  return getCheckoutPath(product, 'processing');
};

export const getSignupPath = (product) => {
  return getCheckoutPath(product, 'signup');
};

export const getLoginPath = (product) => {
  return getCheckoutPath(product, 'login');
};

export const getPurchasePath = (product) => {
  return getCheckoutPath(product, 'purchase');
};

export const getGiftPath = (product) => {
  return getCheckoutPath(product, 'gift');
};

export const isFree = (price) => price.discount_percent === 100;

export const isFreeTVOD = (price) => isFree(price) && isTvod(price);

export const getProductSku = (product) =>
  product.is_subscription ? 'subscribe' : product.sku;

export const isGift = (purchaseOption) =>
  GIFT_OPTIONS.includes(purchaseOption.frequency);

export const isTvod = (purchaseOption) =>
  TVOD_OPTIONS.includes(purchaseOption.frequency);

export const isTvodPromoEligible = (purchaseOption) => {
  return PROMO_ELIGIBLE_OPTIONS_TVOD.includes(purchaseOption.frequency);
};

export const isCustomerLoggedIn = (customer) => !!customer.id;

export const formatIntoCents = (value, isZeroDecimal) => {
  const price = Number(value);
  return Math.round(price * (isZeroDecimal ? 1 : 100));
};

export const formatFromCents = (value, isZeroDecimal) => {
  const fractionDigits = isZeroDecimal ? 0 : 2;
  const subUnits = Math.pow(10, fractionDigits);
  return (value / subUnits).toFixed(0);
};

export const isMobile = () => window.innerWidth <= 480;

export const isGiftPath = (product, match = window) => {
  const sku = product.is_subscription ? 'subscribe' : product.sku;
  return match.location.pathname.includes(`/checkout/${sku}/gift`);
};

export const getLogoutPath = () => {
  return `${LOGOUT_PATH}?return_to=${
    returnToUrl() ||
    encodeURIComponent(window.location.pathname + window.location.search)
  }`;
};

export const handleLogout = () => {
  const requestHeaders = {
    Accept: 'application/json',
    ...internalHeaders,
  };

  const requestOptions = {
    method: 'POST',
    headers: requestHeaders,
  };

  const checkoutLogoutPath = () => {
    return `${
      returnToUrl() || window.location.pathname + window.location.search
    }`;
  };

  fetch('/logout', requestOptions)
    .then(resolveRequest)
    .then(() => {
      window.location.href = checkoutLogoutPath();
    });
};

/**
 * Assign user to window._current_user
 * @param {Object} user - Logged in user
 * @param {number} user.id - id of user
 * @param {string} user.name - name of user
 * @param {string} user.email - email of user
 */
export const setCurrentUser = (user) => {
  window._current_user.id = user.id;
  window._current_user.name = user.name;
  window._current_user.email = user.email;
};

export const changeRegistrationType = (
  product,
  router,
  errorReset,
  setRegistrationType,
  registrationType,
) => {
  setRegistrationType(registrationType);
  const path =
    registrationType === LOGIN ? getLoginPath(product) : getSignupPath(product);
  errorReset({ from: 'payment' });
  router.push(path);
};

export const getFreeTrialEndDate = (freeTrialDays) => {
  return moment().add(freeTrialDays, 'days');
};

export const shouldShowPostalCodeForCountry = (country) => {
  return POSTAL_CODE_COUNTRIES.includes(country);
};

export const getPromotionEndDate = (promotion_duration, frequency) => {
  return moment().add(promotion_duration, frequency.slice(0, -2));
};

/**
 * Gets the price of a checkout. The transaction value is the source of truth
 * because it excludes taxes and discounts. If transaction is undefined,
 * default to the purchase option value.
 * @param {Object} props
 * @param {Object} [transaction] - Will be undefined for begin_checkout event
 * @return {number}
 */
const getGa4Price = (props, transaction) =>
  transaction?.conversion_event_value ||
  props.purchaseOption.conversion_event_value;

/**
 * Gets the item for the conversion event params.
 * @param {Object} props
 * @param {Object} [transaction] - Will be undefined for begin_checkout event
 * @return {Object} - The coupon and discount fields will be nil for begin_checkout events.
 */
const getGa4Item = (props, transaction) => {
  const item = {
    ...CONVERSION_EVENT_CATEGORY_MAP[props.purchaseOption.frequency],
    coupon: props.purchaseOption.promoCode,
    currency: props.purchaseOption.currency?.toUpperCase(),
    discount: props.purchaseOption.conversion_event_discount,
    item_id: props.product.sku,
    item_name: props.product.name,
    price: getGa4Price(props, transaction),
  };

  return omitBy(item, isNil);
};

/**
 * Gets params for conversion events (begin_checkout and purchase).
 * @param {Object} props
 * @param {Object} [transaction] - Will be undefined for begin_checkout event
 * @return {Object} - The checkout_method, coupon, discount, tax, and
 * transaction_id fields will be nil for begin_checkout events.
 */
const getGa4ConversionParams = (props, transaction) => {
  const conversionParams = {
    checkout_method: transaction?.payment_method_type,
    coupon: props.purchaseOption.promoCode,
    currency: props.purchaseOption.currency?.toUpperCase(),
    discount: props.purchaseOption.conversion_event_discount,
    items: [getGa4Item(props, transaction)],
    tax: transaction?.conversion_event_tax,
    transaction_id: transaction?.id,
    user_id: props.customer.id,
    value: getGa4Price(props, transaction),
  };

  return omitBy(conversionParams, isNil);
};

/**
 * Fire purchase event
 * @param {Object} props - PaymentForm props
 * @param {Object} transaction - Successful transaction
 * @returns void
 */
export const trackPurchaseGa4 = (props, transaction) => {
  window.Stats.trackEvent(
    window.Tracking.GOOGLE_ANALYTICS_4.EVENTS.PURCHASE,
    getGa4ConversionParams(props, transaction),
  );
};

/**
 * Fire begin_checkout event
 * @param {Object} props - PaymentForm props
 * @returns void
 */
export const trackBeginCheckoutGa4 = (props) => {
  window.Stats.trackEvent(
    window.Tracking.GOOGLE_ANALYTICS_4.EVENTS.BEGIN_CHECKOUT,
    getGa4ConversionParams(props),
  );
};

export const submitPaymentElement = async (
  submit,
  handleError,
  createPaymentMethod,
) => {
  const { error } = await submit();

  if (error) {
    handleError({
      message: error.message,
      resetElements: true,
    });
  } else {
    await createPaymentMethod();
  }
};

export const stripeAppearanceApiOptions = {
  labels: 'floating',
  variables: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    borderRadius: '3px',
    colorBackground: `${White}`,
    colorTextPlaceholder: `${White}`,
    colorWarning: `${SunsetOrange}`,
    colorDanger: `${SunsetOrange}`,
  },
  rules: {
    '.Input': {
      border: '1px solid rgb(179, 191, 200)',
    },
    '.Input:focus': {
      outline: '1px solid transparent',
      border: `1px solid ${AstroGranite}`,
      boxShadow: `0 0 0 1px ${AstroGranite}`,
    },
    '.Input--invalid': {
      border: `1px solid ${SunsetOrange}`,
      boxShadow: `0 0 0 0`,
      outline: '1px solid transparent',
      color: AstroGranite,
    },
    '.Label': {
      color: RegentGray,
      fontWeight: '400',
    },
    '.Label--floating': {
      fontWeight: '750',
      fontSize: `${small}`,
    },
  },
};
